import React, { useEffect } from "react";
import "./App.css";
import axios from "axios";

function Getreceipt(props) {
    const [receipts, setReceipts] = React.useState([]);
    const [load, setLoad] = React.useState(true);

    useEffect(() => {
        getData();
    }, []);

    const getData = () => {
        axios
            .get("https://receptapi.onrender.com/api/recept")
            .then((res) => {
                setReceipts(res.data);
                setLoad(false);
            })
            .catch((err) => {
                console.log(err);
            });
    };

    const dateToString = (date) => {
        const dateSplit = date.split("T");
        const dateSplit2 = dateSplit[0].split("-");
        return dateSplit2[2] + "." + dateSplit2[1] + "." + dateSplit2[0];
    };

    if (load) {
        return <div>Loading...</div>;
    }
    return (
        <div
            className="App"
            style={{
                display: "flex",
                flexDirection: "column",
                margin: "10px",
            }}
        >
            <h1>Get Receipts</h1>
            <div
                style={{
                    display: "flex",
                    flexDirection: "column",
                    margin: "auto",
                    padding: "10px",
                    borderRadius: "10px",
                    backgroundColor: "white",
                    width: "500px",
                    overflow: "scroll",
                    height: "500px",
                }}
            >
                {receipts.map((receipt, i) => (
                    <div
                        key={"div" + i}
                        style={{
                            display: "flex",
                            flexDirection: "column",
                            margin: "auto",
                            marginTop: "20px",
                            padding: "10px",
                            borderRadius: "10px",
                            backgroundColor: "lightblue",
                            width: "300px",
                            textAlign: "left",
                        }}
                    >
                        <p key={"p1" + i}>
                            <b key={"b1" + i}>Date:</b>{" "}
                            {dateToString(receipt.date)}
                        </p>
                        <p key={"p2" + i}>
                            <b key={"b2" + i}>Amount:</b> {receipt.amount}€
                        </p>

                        <p key={"p3" + i}>
                            <b key={"b3" + i}>Shop:</b> {receipt.shop}
                        </p>

                        <p key={"p4" + i}>
                            <b key={"b4" + i}>Category:</b> {receipt.category}
                        </p>
                    </div>
                ))}
            </div>{" "}
            <button
                onClick={getData}
                style={{
                    fontSize: "15px",
                    maxWidth: "200px",
                    padding: "10px",
                    borderRadius: "10px",
                    color: "white",
                    backgroundColor: "blue",
                    margin: "auto",
                }}
            >
                Refresh
            </button>
        </div>
    );
}

export default Getreceipt;
