import React from "react";
import axios from "axios";
import "./App.css";

const Search = () => {
    const [select, setSelect] = React.useState();
    const [search, setSearch] = React.useState();
    const [receipt, setReceipt] = React.useState([]);

    const url =
        "https://receptapi.onrender.com/api/recept/sorted?" +
        select +
        "=" +
        search;

    const searchButton = () => {
        axios.get(url).then((result) => {
            console.log(receipt);
            setReceipt(result.data);
        });
    };

    return (
        <div
            style={{ display: "flex", flexDirection: "column", margin: "20px" }}
        >
            <h1
                style={{
                    textAlign: "center",
                    fontFamily: "fantasy",
                    fontSize: "50px",
                    fontStyle: "italic",
                    marginBottom: "30px",
                }}
            >
                Your Receipt
            </h1>
            <div>
                <select
                    type="text"
                    onChange={(e) => setSelect(e.target.value)}
                    className="input"
                >
                    <option value="">Valitse</option>
                    <option value="id">ID</option>
                    <option value="date">Date</option>
                    <option value="amount">Amount</option>
                    <option value="shop">Shop</option>
                    <option value="category">Category</option>
                </select>
                <input
                    type="text"
                    onChange={(e) => setSearch(e.target.value)}
                    className="input"
                />
                <button
                    onClick={() => searchButton()}
                    style={{
                        fontSize: "20px",
                        maxWidth: "200px",
                        padding: "10px",
                        borderRadius: "10px",
                        color: "white",
                        backgroundColor: "blue",
                        margin: "auto",
                    }}
                >
                    Search
                </button>
            </div>

            {receipt.length > 0 ? (
                receipt.map((receipt, i) => (
                    <div
                        key={"div" + i}
                        style={{
                            display: "flex",
                            flexDirection: "column",
                            margin: "auto",
                            marginTop: "20px",
                            padding: "10px",
                            borderRadius: "10px",
                            backgroundColor: "lightblue",
                            width: "300px",
                            textAlign: "left",
                        }}
                    >
                        <p key={"p1" + i}>
                            <b key={"b1" + i}>Date:</b> {receipt.date}
                        </p>
                        <p key={"p2" + i}>
                            <b key={"b2" + i}>Amount:</b> {receipt.amount}€
                        </p>

                        <p key={"p3" + i}>
                            <b key={"b3" + i}>Shop:</b> {receipt.shop}
                        </p>

                        <p key={"p4" + i}>
                            <b key={"b4" + i}>Category:</b> {receipt.category}
                        </p>
                    </div>
                ))
            ) : (
                <div style={{ textAlign: "center" }}>No data!</div>
            )}
        </div>
    );
};

export default Search;
