import React from "react";
import "./App.css";
import Addreceipt from "./Addreceipt";
import Getreceipt from "./Getreceipt";
import Search from "./Search";

function Main() {
    return (
        <div
            style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
            }}
        >
            <Search />
            <div
                className="App"
                style={{
                    display: "flex",
                    flexDirection: "row",
                    flexWrap: "wrap",
                    margin: "10px",
                    alignItems: "center",
                    justifyContent: "center",
                }}
            >
                <Addreceipt />
                <Getreceipt />
            </div>
        </div>
    );
}

export default Main;
