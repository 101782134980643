import React from "react";
import "./App.css";
import axios from "axios";

function Addreceipt() {
    const [date, setDate] = React.useState("");
    const [amount, setAmount] = React.useState(0);
    const [category, setCategorie] = React.useState("");
    const [shop, setShop] = React.useState("");

    const addReceipt = (props) => {
        const newReceipt = {
            date: date,
            amount: Number(amount),
            shop: shop,
            category: category,
        };

        axios
            .post("https://receptapi.onrender.com/api/recept", newReceipt)
            .then((res) => {
                alert("Success");
                setAmount(0);
                setDate("");
                setCategorie("");
                setShop("");
            })
            .catch((err) => {
                alert("Failed, try again!");
            });
    };

    return (
        <div
            className="App"
            style={{
                display: "flex",
                flexDirection: "column",
                margin: "10px",
            }}
        >
            <h1>Add Receipt</h1>
            <div
                style={{
                    display: "flex",
                    flexDirection: "column",
                    margin: "auto",
                }}
            >
                <br />
                <label>Date: </label>
                <input
                    type="Date"
                    onChange={(e) => setDate(e.target.value)}
                    className="input"
                    value={date}
                />
                <br />
                <label>Amount: </label>
                <input
                    type="number"
                    onChange={(e) => setAmount(e.target.value)}
                    className="input"
                    value={amount}
                />
                <br />
                <label>Shop: </label>
                <input
                    type="text"
                    onChange={(e) => setShop(e.target.value)}
                    className="input"
                    value={shop}
                />
                <br />
                <label>Category: </label>
                <input
                    type="text"
                    onChange={(e) => setCategorie(e.target.value)}
                    className="input"
                    value={category}
                />
                <br />
                <button
                    onClick={addReceipt}
                    style={{
                        fontSize: "20px",
                        maxWidth: "200px",
                        padding: "10px",
                        borderRadius: "10px",
                        color: "white",
                        backgroundColor: "blue",
                        margin: "auto",
                    }}
                >
                    Add Receipt
                </button>
            </div>
        </div>
    );
}

export default Addreceipt;
